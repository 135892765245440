<template>
    <div style="min-width: 100vw; min-height: 100vh;background-color: #000000;">
        <div :class="videoImgWidth>videoImgHeight?'box':''" ref="videoBox">
            <div class="video-box" :style="{ height: videoHeight + 'px'}">
                <video ref="videoPlayer" id="myVideo" :style="{ height: videoHeight + 'px',width: videoWeight + 'px'}"
                    :src="details.videoPath" loop controls :poster="video_list.userVideoImg" @error="videoErrorCallback"
                    :enable-progress-gesture="gesture" @play="handlePlay" @pause="handlePause"></video>
            </div>
            <!-- <div class="video_box" ref="videoBox" :style="{ minHeight: backImgHeight + 'px',paddingBottom: 70 + 'px'}"> -->
            <!-- <div class="video_img_box">
                    <div class="video_img_flex" @click="downLoadVideo">
                        <div class="downLoadImg"></div>
                        <p class="video_img_text">{{$t('downLoad')}}</p>
                    </div>
                    <div class="video_img_flex" @click="userShare">
                        <div class="video_share"></div>
                        <p class="video_img_text">{{$t('share')}}</p>
                    </div>
                </div> -->
            <!-- <div class="video_box_back"></div>
                <div class="video_price">
                    <div class="video_name">{{userVideoName}}</div>
                </div>
                <div class="video_text_back">
                    <div class="video_text">{{userVideoText}}</div>
                </div> -->
            <!-- </div> -->
            <!-- <div class="boxs">
                <div class="videoBox">
                    <div class="videoBoxBack"></div>
                    <div class="video_price">
                        <div class="video_name">{{video_list.userVideoName}}</div>
                    </div>
                </div>
                <div class="video_text_back">
                    <div class="video_text">{{video_list.userVideoText}}</div>
                </div>
            </div> -->
        </div>
    </div>
</template>
<script>
    import { getUserVideoPath } from "@/api/getUserVideoPath.js"
    export default {
        data() {
            return {
                videoHeight: null,
                videoWeight: null,
                videoImgWidth: null,
                videoImgHeight: null,
                backImgHeight: null,
                video_list: [],
                gesture: false,
                videoUrl: '',
                userVideoName: '',
                userVideoText: '',
                details: []
            }
        },
        async mounted() {
            console.log(this.$route.query);
            let data = {
                appAlias: 'USTYLE',
                type: 1,
                videoId: this.$route.query.videoId,
                videoType: this.$route.query.videoType
            }
            console.log(data);
            const res = await getUserVideoPath('https://www.ustyle.cn/display-api/display-model-api/meta/app/getVideoPath', data)
            if (res.data.success) {
                this.details = res.data.data
                console.log(this.details)
            }
            const { videoImgHeight, videoImgWidth } = this.details;
            console.log(videoImgHeight);
            console.log(videoImgWidth);
            this.videoImgWidth = videoImgWidth
            this.videoImgHeight = videoImgHeight
            let windowWidth = window.innerWidth;
            let windowHeight = window.innerHeight;
            let s = (windowWidth / this.$route.query.userVideoImgWidth).toFixed(4);
            this.videoWeight = windowWidth;
            this.videoHeight = this.$route.query.videoImgWidth * s;
            // 宽视频
            if (parseInt(videoImgWidth) > parseInt(videoImgWidth)) {
                const videoBox = this.$refs.videoBox.offsetHeight;
                console.log(videoBox)
                // this.backImgHeight = (windowHeight - this.videoHeight - videoBox) / 2;
                this.backImgHeight = windowHeight - this.videoHeight
                console.log('短视频')
            } else { //长视频
                const videoBox = this.$refs.videoBox.offsetHeight;
                console.log(this.$refs.videoBox)
                this.backImgHeight = (windowHeight - videoBox) / 2;
                console.log("长视频")
            }
        },
        methods: {
            handlePlay() {
                this.gesture = true
            },
            handlePause() {
                this.gesture = false
            },
            videoErrorCallback(e) {
                if (this.gesture) {
                    Toast.fail(e.target.errMsg)
                }
            },
        },
    }
</script>
<style scoped>
    .box {
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -50%;
        margin-top: -25%;
    }

    .boxs {
        width: 100%;
        margin: auto;
        padding-bottom: 70px;
    }

    .video-box {
        width: 100%;
        position: relative;
        display: flex;
        flex-wrap: nowrap;
    }

    #myVideo {
        width: 100%;
        height: 100%;
    }

    .videoBox {
        width: 100%;
        height: 73px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 20px;
        border-bottom: 1px solid #ffffff;
        position: relative;
        background-image: url('../assets/img/video.png');
        background-repeat: no-repeat;
        background-size: cover;
        -webkit-background-size: cover;
        -o-background-size: cover;
        background-position: center;
    }

    .video_box {
        background-image: url('../assets/img/video_back.png');
        background-size: cover;
        position: relative;
        height: max-content;
        position: relative;
        /* padding: 24px 0; */
    }

    .video_img_box {
        width: 100%;
        /* height: 40px; */
        height: max-content;
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: center;
    }

    .video_img_flex {
        width: 40px;
        height: max-content;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        margin-top: 16px;
    }

    .video_img_text {
        margin-top: 4px;
        font-size: 12px;
        color: #ffffff;
    }

    .video_box_back {
        width: 100%;
        height: 100%;
        /* position: absolute; */
        /* background-image: linear-gradient(-180deg, rgba(255, 255, 255, 0) 74%, #fff 98%); */
    }

    .videoBoxBack {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-image: linear-gradient(-180deg, rgba(255, 255, 255, 0) 78%, #f2f2f2 98%);
    }

    .video_box_details {
        width: 90%;
        margin: 0 auto 24px auto;
        white-space: pre-wrap;
        letter-spacing: 2px;
        color: #000000;
        font-size: 16px;
        display: flex;
        flex-wrap: wrap;
    }

    .video_text_back {
        width: 326px;
        background-image: url('../assets/img/text_back.png');
        background-repeat: no-repeat;
        background-size: cover;
        -webkit-background-size: cover;
        -o-background-size: cover;
        background-position: center;
        /* box-shadow: 5px 0px 7px 0px rgb(33 37 41 / 50%); */
        padding: 14px 0;
        border-radius: 5px;
        margin: 0 auto;
        /* position: absolute;
        left: 50%;
        top: 77px;
        margin-left: -163px; */
    }

    .video_text {
        width: 294px;
        margin: 0 auto;
        font-size: 12px;
        color: #ffffff;
        white-space: pre-line;
        /* font-weight: 900; */
        line-height: 19px;
        letter-spacing: 1.5px;
    }

    .video_word_title {
        color: #000000;
        letter-spacing: 2px;
        font-size: 21px;
        padding: 15px 0;
    }

    .box_title_flex_between {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        color: #000000;
        font-size: 15px;
        padding-bottom: 16px;
        width: 90%;
        margin: 0 auto;
    }

    .box_title_child_flex_between {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
    }

    .box_title_child_flex_between>img {
        width: 30px;
        height: 30px;
        padding-right: 7px;
    }


    .video_price {
        width: 100%;
        height: 50px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-around;
        z-index: 999;
    }

    .video_name::after {
        width: 80%;
        margin: 4px auto;
        content: "";
        display: block;
        background: linear-gradient(to right, rgba(40, 150, 252, 1), rgba(255, 255, 255, 0));
        width: 100%;
        height: 1px;
    }

    .video_name {
        color: #ffffff;
        font-size: 21px;
        width: 342px;
        margin: 0 auto;
        padding: 4px 0;
    }

    .downLoadImg {
        width: 24px !important;
        height: 24px !important;
        background-image: url('../assets/img/downLoad.png');
        background-size: cover;
    }

    .video_share {
        width: 24px;
        height: 24px;
        background-image: url('../assets/img/share.png');
        background-size: 100% 100%;
    }

    .video_price_flex {
        display: flex;
        flex-wrap: wrap;
    }

    .video_flex {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        color: #000000;
    }

    .icon_padd {
        padding-right: 5px;
    }

    .iconfont {
        font-size: 21px;
    }

    .icon_color_blue {
        color: #0067FF;
        font-size: 21px;
    }

    .collectPng {
        width: 24px;
        height: 24px;
    }

    .not_collect {
        width: 24px;
        height: 24px;
    }

    .video_detail_text {
        font-size: 14px;
    }
</style>